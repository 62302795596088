import Sidebar from './Sidebar';
import React, {useEffect, useState} from "react";
import {NavLink, Link, useNavigate} from 'react-router-dom';
import UserService from '../services/UserService.js';
import PropertyService from '../services/PropertyService.js';
const BACKOFFICE_URL = "https://backoffice.vedl.no/"; //process.env.REACT_APP_BACKOFFICE_URL;

function Report() {
  const navigate = useNavigate();
  const [propertyList, setPropertyList] = useState([]);
  const [enableDownloadButton, setEnableDownloadButton] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState({
    adresse: "",
    postnr: "",
    poststed: ""
  });
  const username = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  const name = localStorage.getItem('name');
  //console.log("enableDownloadButton", enableDownloadButton);
  const [reportYear, setReportYear] = useState(false);
  const [hideCost, setHideCost] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [activeReports, setActiveReportList] = useState([]);
   const [showMenu, setShowMenu] = useState(false);
  var arstall, kostnad, areal;
  const closeMobileMenu = () => {
      document.getElementById('left-menu').style.display = 'none';
      setShowMenu(false);
    }
  const openMobileMenu = () => {
      console.log(showMenu);
      {showMenu==false ? document.getElementById('left-menu').style.display = 'block' : document.getElementById('left-menu').style.display = 'none'}
      //document.getElementById('left-menu').style.display = {showMenu=='true' ? 'block' : 'none'};
       setShowMenu(!showMenu);
    }

  const handelYearCheck = () => {
    setReportYear(!reportYear);
  }
  const handelCostCheck = () => {
    setHideCost(!hideCost);
  }
  const handelAreaCheck = () => {
    setShowArea(!showArea);
  }
  //console.log(reportYear, hideCost, showArea);   
  function generateReport(){
    setEnableDownloadButton(true);
    if(reportYear===true){
      arstall = 1;
    } else {
      arstall = 0;
    }
    if(hideCost===true){
      kostnad = 1;
    } else {
      kostnad = 0;
    }
    if(showArea===true){
      areal = 1;
    } else {
      areal = 0;
    }
    PropertyService.generateReport(localStorage.getItem("propertyid"), arstall, areal, kostnad).then(
        (reportinfo) => {
          setEnableDownloadButton(false);
           window.location.href = BACKOFFICE_URL+"dashboard/dashboardapi/"+reportinfo;
        },
        (error) => {
          console.log(error);
        }
      );
    
  }
    const sendMaintenanceReportEmail = () => {
      UserService.sendMaintenanceReportEmail(propertyInfo, name, email);
     }

    const sendServiceEmail = () => {
      UserService.sendServiceEmail(propertyInfo, name, email);
    }
  const changeProperty = (event) => {
      if(event.target.value==="0"){
        return;
      } else {

        localStorage.setItem("propertyid", "")
        localStorage.setItem("propertyid", event.target.value)
      }

     
      //fetching active report
      PropertyService.getActiveReports(event.target.value).then(
        (reportlist) => {
          setActiveReportList(reportlist);
        },
        (error) => {
          console.log(error);
        }
      );
     

      PropertyService.propertyInfo(event.target.value).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

    
      
      
      
    }

    useEffect(() => {
      
       
        //console.log("kunderid", localStorage.getItem("kunderid"))
        //console.log("propertyid", localStorage.getItem("propertyid"))
        const loggedin = localStorage.getItem("loggedin");
        if(loggedin!='true'){
          navigate("/login");
        }

        if(localStorage.getItem("buildingid")==="null"){
          return;
        }
        if(localStorage.getItem("propertyid")==="null"){
          return;
        }
       


      

       PropertyService.propertyInfo(localStorage.getItem("propertyid")).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

     //fetching active report
      PropertyService.getActiveReports(localStorage.getItem("propertyid")).then(
        (reportlist) => {
          setActiveReportList(reportlist);
        },
        (error) => {
          console.log(error);
        }
      );
     

      
      //fetching users properties
      PropertyService.userPropertyList(username).then(
        (propertylist) => {
          setPropertyList(propertylist);
        },
        (error) => {
          console.log(error);
        }
      );
      
    }, [navigate, username])
  return (
    <div>
    <div className="main_container">
  	 <div className="top-header">
      <div className="header-center">
          <div className="mobile-ham-menu"> 
           <img src="img/ham-menu-icon.png" onClick={openMobileMenu} />
           </div>
          <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="top-search">
              

              <select onChange={changeProperty} defaultValue={localStorage.getItem("propertyid")}>
                  <option key="0" value="0">Velg Eiendom</option>
                {propertyList.map((value, key) => (
                  <option selected={value.companyId===localStorage.getItem("propertyid") ? true : false} onClick={changeProperty} key={key} value={value.companyId}>{value.companyName}</option>
                  ))}
                
              </select>
            </div>

            <div className="profile-area">
              <NavLink to="/profile" title="Profil"><img alt="" src="img/profile-icon.png" height="35" /></NavLink>
              <div className="profile-username">{username}</div>
              <div className="logout-link"><Link className="logout-link" to="/logout">Logout</Link></div>
              </div>
            <div className="clear"></div>
            
        </div>
    </div>
    
    <div className="body-container" id="body-container">
    	
        <Sidebar />
        <div className="right-content"  onClick={closeMobileMenu}>
        	<div className="content-top">
            	<div className="content-top-left"><h1>{propertyInfo.adresse===""  ? "" : propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed}</h1></div>
                <div className="content-top-right">
                  <div className="top-btn"  onClick={() => sendServiceEmail()}><a href="javascript:void(0)">Bestill tjenester</a></div>
                  <div className="top-btn" onClick={() => sendMaintenanceReportEmail()}><a href="javascript:">Bestill vedlikeholdsrapport</a></div>
                </div>
                <div className="clear"></div>
            </div>
            
            <div className="right-main-content">
              
              <div className="floatleft" style={{width:'65%', display: 'none'}}>
                
                <div className="report-help-area">
                  <h1>Vedlikeholdsrapport</h1>
                </div>
                
                <div className="report-radio-area">
                  <div>
                    <div className="radio">
                      <div className="floatleft">
                        <label className="switch">
                          <input type="checkbox" onChange={handelYearCheck}  />
                          <span className="slider round"></span>

                        </label>
                      </div>
                      <div className="floatleft swtich-text">
                      VIS &aring;rstall (ikke dato) for utf&oslash;rt vedlikehold
                      </div>
                    </div>

                    <div className="radio">
                      <div className="floatleft">
                        <label className="switch">
                          <input type="checkbox"  onChange={handelCostCheck}  />
                          <span className="slider round"></span>

                        </label>
                      </div>
                      <div className="floatleft swtich-text">
                      Skjul kostnad i vedlikeholstabell
                      </div>
                    </div>

                    <div className="radio">
                      <div className="floatleft">
                        <label className="switch">
                          <input type="checkbox"  onChange={handelAreaCheck}  />
                          <span className="slider round"></span>

                        </label>
                      </div>
                      <div className="floatleft swtich-text">
                      VIS arealer
                      </div>
                    </div>

                  </div>
                  <div className="divider"></div>
                  <div className="clear"></div>
                  <div className="divider"></div>
                  <div className="clear"></div>
                  <div>
                    <button disabled={enableDownloadButton} className="report_button" onClick={() => generateReport()}>Last ned rapport</button>
                  </div>

                </div>
                <div className="divider"></div>
                <div className="clear"></div>
              </div>
              <div className="floatleft" style={{width:'2%', display:'none'}}>
              &nbsp;
              </div>
              <div className="floatleft" style={{width:'98%'}}>
                <div className="report-help-area">
                  <h1>Rapporter</h1>
                </div>
                <div className="report-radio-area">
                  <div>
                  {activeReports ? 
                    <>
                      <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                          <tbody>
                          <tr align="left">
                            <th scope="col">Filnavn</th>
                            <th scope="col">Dato</th>
                            <th scope="col">Vedlegg</th>
                            <th scope="col">Type</th>
                          </tr>
                          <tr>
                            <td colSpan="7">&nbsp;</td>
                          </tr>
                      {activeReports.map((value, key) => (
                        <>
                        <tr className="db-table-tr">
                                <td>{value.filename}</td>
                                <td>{value.dato}</td>
                                <td><a href={BACKOFFICE_URL+''+value.web_path} ><img alt="pdf-icon" src="img/pdf-icon.png" /></a></td>
                                <td>{value.endelig==0 ? 'Endelig' : 'Midlertidig'}</td>
                               
                              </tr>
                              <tr>
                                <td colSpan="7"><hr /></td>
                              </tr>
                        </>

                      ))}
                      </tbody>
                          </table>  
                    </>
                   : '' }
                  <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>

            
        </div>
        
        <div className="clear"></div>
    </div>
    
    
</div>
</div>
    )
}
export default Report;
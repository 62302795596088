import Sidebar from './Sidebar';
import React,{useEffect, useState} from "react";
import { useLocation, useNavigate, Link, NavLink  } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserService from '../services/UserService.js';
import PropertyService from '../services/PropertyService.js';
const BACKOFFICE_URL = "https://backoffice.vedl.no/";

function HomePage() {
    const location = useLocation();
    console.log(window.location.origin);
    const navigate = useNavigate();
    const [enableVedlikeSubmitButton, setenableVedlikeSubmitButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [startAvtalerDate, setAvtalerStartDate] = useState(new Date());
    const [expireAvtalerDate, setAvtalerExpireDate] = useState(new Date());
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalAvtalerIsOpen, setAvtalerIsOpen] = useState(false);
    const [modalVedlikeIsOpen, setVedlikeIsOpen] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [welcomeText, toggleWelcomeText] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: 'auto',
        height: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        textAlign:'left'
      },
    };
    const [propertyInfo, setPropertyInfo] = useState({
      adresse: "",
      postnr: "",
      poststed: ""
    });
    const username = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');
    //const userid = localStorage.getItem('userid');
    const [pendingTask, setPendingTask] = useState([]);
    //console.log(pendingTask.red);
    const [activityList, setActivityList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [buildingInfo, setBuildingInfo] = useState([]);
    const [taskId, setTaskId] = useState([]);
    const [hmsActionFile, sethmsActionFile] = useState(null);
    const [vedlikeFile, setVedlikeFile] = useState(null);
    const [AvtalerFile, setAvtalerActionFile] = useState(null);
    const handleVedlikeFileUpload = (event) => {
      setVedlikeFile(event.target.files[0]);
    };

    const handleHMSFileUpload = (event) => {
      sethmsActionFile(event.target.files[0]);
    };

    const handleAvtalerFileUpload = (event) => {
      setAvtalerActionFile(event.target.files[0]);
    };
    const [state, setState] = useState({
      saksgang: "",
      medlt_av: ""
    });

    const [stateVedl, setVedlikeState] = useState({
      comments: "",
      utfort_av: ""
    });

    const [stateAvtaler, setAvtalerState] = useState({
      comments: ""
    });

    const handleVedlikeField = event => {
      
      const { name, value } = event.target;
      setVedlikeState(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

  const handleHMSField = event => {
    if(event.keyCode==='13'){
      //handleHMSSubmit(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAvtalerField = event => {
    if(event.keyCode==='13'){
     // handleHMSSubmit(event);
    }
    const { name, value } = event.target;
    setAvtalerState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleVedlikeSubmit = () => {
      setenableVedlikeSubmitButton(true);
      const formData = new FormData();
      formData.append("vedlikefile", vedlikeFile);
      formData.append("comments", stateVedl.comments);
      formData.append("utfort_av", stateVedl.utfort_av);
      formData.append("user", name);
      formData.append("action_id", taskId);
      formData.append("eiendomid", localStorage.getItem("propertyid"));
      formData.append("bygningsid", localStorage.getItem("buildingid"));
      formData.append("kundenr", localStorage.getItem("kunderid"));

      //fetching pending tasks number
      PropertyService.vedlikePlanUpdate(formData).then(
        (vedlikeinfo) => {
          // handle the response
            setenableVedlikeSubmitButton(false);
            setVedlikeIsOpen(false);
            document.getElementById(taskId+'-status-Vedlikeholdsplan').style.backgroundColor = 'green'
            document.getElementById(taskId+'-status-Vedlikeholdsplan').innerText = 'Gjennomført'

            //fetching pending tasks number
            PropertyService.pendingTask(localStorage.getItem("propertyid")).then(
              (pendingtask) => {
                setPendingTask(pendingtask);
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );


     
  }
  const handleHMSSubmit = () => {
    setenableVedlikeSubmitButton(true);
    const formData = new FormData();
    formData.append("hmsactionfile", hmsActionFile);
    formData.append("saksgang", state.saksgang);
    formData.append("medlt_av", state.medlt_av);
    formData.append("dato_sak_avsluttet", startDate);
    formData.append("action_id", taskId);
    formData.append("user", name);
      //fetching pending tasks number
      PropertyService.hmsActionUpdate(formData).then(
        (vedlikeinfo) => {
          // handle the response
          setenableVedlikeSubmitButton(false);
          setIsOpen(false);
          document.getElementById(taskId+'-status-HMS').style.backgroundColor = 'green'
          document.getElementById(taskId+'-status-HMS').innerText = 'Gjennomført'
          //fetching pending tasks number
          PropertyService.pendingTask(localStorage.getItem("propertyid")).then(
            (pendingtask) => {
              setPendingTask(pendingtask);
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );


    
  }

  const handleAvtalerSubmit = () => {
      setenableVedlikeSubmitButton(true);
      const formData = new FormData();
      formData.append("avtalerfile", AvtalerFile);
      formData.append("comments", stateAvtaler.comments);
      formData.append("start_date", startAvtalerDate);
      formData.append("expiry_date", expireAvtalerDate);
      formData.append("user", name);
      formData.append("action_id", taskId);
      formData.append("eiendomid", localStorage.getItem("propertyid"));
      formData.append("bygningsid", localStorage.getItem("buildingid"));
      formData.append("kundenr", localStorage.getItem("kunderid"));

      //fetching pending tasks number
      PropertyService.avtalerUpdate(formData).then(
        (vedlikeinfo) => {
          // handle the response
            setenableVedlikeSubmitButton(false);
            setAvtalerIsOpen(false);
            document.getElementById(taskId+'-status-Avtaler').style.backgroundColor = 'green'
            document.getElementById(taskId+'-status-Avtaler').innerText = 'Gjennomført'
          //fetching pending tasks number
          PropertyService.pendingTask(localStorage.getItem("propertyid")).then(
                (pendingtask) => {
                  setPendingTask(pendingtask);
                },
                (error) => {
                  console.log(error);
                }
              );
        },
        (error) => {
          console.log(error);
        }
      );


      
  }
    //let subtitle;
    function openHMSModal(taskid) {
      setTaskId(taskid);
      setIsOpen(true);
    }

    function openVedlikeModal(taskid) {
      setTaskId(taskid);
      setVedlikeIsOpen(true);
    }

    function openAvtalerModal(taskid) {
      setTaskId(taskid);
      setAvtalerIsOpen(true);
    }
    
    
    function closeHMSModal(){
      setIsOpen(false);
    }
    function closeVedlikeModal(){
      setVedlikeIsOpen(false);
    }
     function closeAvtalerModal(){
      setAvtalerIsOpen(false);
    }
    
    //console.log("emailList", emailList);


    const buildingName = buildingInfo.navn ? buildingInfo.navn : '';
    const buildingYear = buildingInfo.byggar ? buildingInfo.byggar : '';
    const buildingImage = buildingInfo.web_path ? BACKOFFICE_URL+''+buildingInfo.web_path : '';
    const buildingDescription = buildingInfo.beskrivelse ? buildingInfo.beskrivelse.substring(0, 600)+'...' : '';
    //console.log(localStorage.getItem('propertyid'), localStorage.getItem('kunderid'), localStorage.getItem('buildingid'));
    //console.log("localStorage.getItem('propertyid')", localStorage.getItem('propertyid'));
    

    const sendEmailToAll = () => {
      var emailLisst = '';
      contactList.map((value) => (
        emailLisst += value.epost+';'
      ));
      window.location.href = 'mailto: '+emailLisst
    }

    const sendMaintenanceReportEmail = () => {
      UserService.sendMaintenanceReportEmail(propertyInfo, name, email);
     }

    const sendServiceEmail = () => {
      UserService.sendServiceEmail(propertyInfo, name, email);
    }

    
    
    const changeBuilding = (event) => {
      if(event.target.value==="0"){
        return;
      }

      PropertyService.changeBuilding(event.target.value).then(
        (building) => {
          setBuildingInfo(building);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    const closeMobileMenu = () => {
      document.getElementById('left-menu').style.display = 'none';
      setShowMenu(false);
    }
    const openMobileMenu = () => {
      console.log(showMenu);
      {showMenu==false ? document.getElementById('left-menu').style.display = 'block' : document.getElementById('left-menu').style.display = 'none'}
      //document.getElementById('left-menu').style.display = {showMenu=='true' ? 'block' : 'none'};
       setShowMenu(!showMenu);
    }

    const changeProperty = (event) => {
      if(event.target.value==="0"){
        return;
      } else {

        localStorage.setItem("propertyid", "")
        localStorage.setItem("propertyid", event.target.value)
      }
      PropertyService.buildingList(event.target.value).then(
        (buildingList) => {
          setBuildingList(buildingList);
          setSelectedBuilding(buildingList[0].bygningerid);
          PropertyService.changeBuilding(buildingList[0].bygningerid).then(
            (building) => {
              setBuildingInfo(building);
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching pending tasks number
      PropertyService.pendingTask(event.target.value).then(
        (pendingtask) => {
          setPendingTask(pendingtask);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching tasks
      PropertyService.taskList(event.target.value).then(
        (tasklist) => {
          setTaskList(tasklist);
        },
        (error) => {
          console.log(error);
        }
      );
      
      PropertyService.propertyInfo(event.target.value).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      PropertyService.activityList(event.target.value).then(
        (activitylist) => {
          setActivityList(activitylist);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching kunderid
      PropertyService.getKunder(event.target.value).then(
        (kunderinfo) => {
          localStorage.setItem("kunderid", "")
          localStorage.setItem("kunderid", kunderinfo)
          PropertyService.kontakterList(kunderinfo).then(
            (kontakterlist) => {
              setContactList(kontakterlist);
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );
    }

     
    useEffect(() => {
      
       
        //console.log("kunderid", localStorage.getItem("kunderid"))
        //console.log("propertyid", localStorage.getItem("propertyid"))
        const loggedin = localStorage.getItem("loggedin");
        if(loggedin!='true'){
          navigate("/login");
        }

        if(localStorage.getItem("buildingid")==="null"){
          return;
        }
        if(localStorage.getItem("propertyid")==="null"){
          return;
        }
       

      PropertyService.buildingList(localStorage.getItem("propertyid")).then(
        (buildingList) => {
          setBuildingList(buildingList);
          setSelectedBuilding(buildingList[0].bygningerid);
          PropertyService.changeBuilding(buildingList[0].bygningerid).then(
            (building) => {
              setBuildingInfo(building);
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );

      PropertyService.propertyInfo(localStorage.getItem("propertyid")).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching pending tasks number
      PropertyService.pendingTask(localStorage.getItem("propertyid")).then(
        (pendingtask) => {
          setPendingTask(pendingtask);
        },
        (error) => {
          console.log(error);
        }
      );
   
      //fetching tasks
      PropertyService.taskList(localStorage.getItem("propertyid")).then(
        (tasklist) => {
          setTaskList(tasklist);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching activities
      PropertyService.activityList(localStorage.getItem("propertyid")).then(
        (activitylist) => {
          setActivityList(activitylist);
        },
        (error) => {
          console.log(error);
        }
      );
      
      
      //fetching board
      PropertyService.kontakterList(localStorage.getItem("kunderid")).then(
        (kontakterlist) => {
          setContactList(kontakterlist);
        },
        (error) => {
          console.log(error);
        }
      );
      
      
      //fetching users properties
      PropertyService.userPropertyList(username).then(
        (propertylist) => {
          setPropertyList(propertylist);
        },
        (error) => {
          console.log(error);
        }
      );
      
    }, [navigate, username])

  return (
    <div>
    <div className="main_container" id="main_container">
	  <div className="top-header">
      <div className="header-center">
           <div className="mobile-ham-menu"> 
           <img src="img/ham-menu-icon.png" onClick={openMobileMenu} />
           </div>

          <div className="logo"> 

            <img src="img/logo.png" width="180" height="48" alt="" />
          </div>
            <div className="top-search">
              

              <select onChange={changeProperty} defaultValue={localStorage.getItem("propertyid")}>
                  <option key="0" value="0">Velg Eiendom</option>
                {propertyList.map((value, key) => (
                  <option selected={value.companyId===localStorage.getItem("propertyid") ? true : false} onClick={changeProperty} key={key} value={value.companyId}>{value.companyName}</option>
                  ))}
                
              </select>
            </div>

            <div className="profile-area">
              <NavLink to="/profile" title="Profil"><img alt="" src="img/profile-icon.png" height="35" /></NavLink>
              <div className="profile-username">{username}</div>
              <div className="logout-link"><Link className="logout-link" to="/logout">Logout</Link></div>
              </div>
            <div className="clear"></div>
            
        </div>
    </div>
    
    <div className="body-container" id="body-container">
    	
        <Sidebar />
         <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeHMSModal}
          style={customStyles}
          contentLabel="Date Modal"
        >
        
          Saksgang:
          <br />
          <textarea className="txtarea" placeholder="Saksgang" name="saksgang" onChange={handleHMSField}></textarea>
          <br /><br />
          Medlt av:
          <br />
          <textarea className="txtarea" placeholder="Medlt av" name="medlt_av" onChange={handleHMSField}></textarea>
          <br /><br />
          Vedlegg:
          <br />
          <input type="file" className="inpfile" onChange={handleHMSFileUpload} />
          <br /><br />
          Sak avsluttet dato:
          <br />
          <DatePicker className="inpfile" selected={startDate} onChange={(date) => setStartDate(date)} />
          <br /><br />
          <div style={{float: 'left', marginLeft: '40px'}}>
          <button className="savenclose" disabled={enableVedlikeSubmitButton} onClick={handleHMSSubmit}>Save & Close</button>
          </div>
          <div style={{float: 'left', marginLeft: '10px'}}>
          <button className="savenclose" onClick={closeHMSModal}>Cancel</button>
          </div>
        </Modal> 

        <Modal
          isOpen={modalVedlikeIsOpen}
          onRequestClose={closeVedlikeModal}
          style={customStyles}
          contentLabel="Date Modal"
        >
        
          Beskrivlese:
          <br />
          <textarea className="txtarea" placeholder="Beskrivlese" name="comments" onChange={handleVedlikeField}></textarea>
          <br /><br />
          utført av:
          <br />
          <textarea className="txtarea" placeholder="Utført av" name="utfort_av" onChange={handleVedlikeField}></textarea>
          <br /><br />
          Vedlegg:
          <br />
          <input type="file" className="inpfile" onChange={handleVedlikeFileUpload} />
          <br /><br />
          <div style={{float: 'left', marginLeft: '40px'}}>
          <button disabled={enableVedlikeSubmitButton} className="savenclose" onClick={handleVedlikeSubmit}>Save & Close</button>
          </div>
          <div style={{float: 'left', marginLeft: '10px'}}>
          <button className="savenclose" onClick={closeVedlikeModal}>Cancel</button>
          </div>
        </Modal>
        <Modal
          isOpen={modalAvtalerIsOpen}
          onRequestClose={closeAvtalerModal}
          style={customStyles}
          contentLabel="Date Modal"
        >
        
          Beskrivlese:
          <br />
          <textarea className="txtarea" placeholder="Beskrivlese" name="comments" onChange={handleAvtalerField}></textarea>
          <br /><br />
          Start dato:
          <br />
          <DatePicker className="inpfile" selected={startAvtalerDate} onChange={(date) => setAvtalerStartDate(date)} />
          <br /><br />
          Utløps dato:
          <br />
          <DatePicker className="inpfile" selected={expireAvtalerDate} onChange={(date) => setAvtalerExpireDate(date)} />
          <br /><br />
          Vedlegg:
          <br />
          <input type="file" className="inpfile" onChange={handleAvtalerFileUpload} />
          <br /><br />
          <div style={{float: 'left', marginLeft: '40px'}}>
          <button disabled={enableVedlikeSubmitButton} className="savenclose" onClick={handleAvtalerSubmit}>Save & Close</button>
          </div>
          <div style={{float: 'left', marginLeft: '10px'}}>
          <button className="savenclose" onClick={closeAvtalerModal}>Cancel</button>
          </div>
        </Modal>
        <div className="right-content" onClick={closeMobileMenu}>
        	<div className="content-top">
            	<div className="content-top-left"><h1>{propertyInfo.adresse===""  ? "" : propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed}</h1></div>
                <div className="content-top-right">
                	<div className="top-btn"  onClick={() => sendServiceEmail()}><a href="javascript:">Bestill tjenester</a></div>
                	<div className="top-btn" onClick={ () => sendMaintenanceReportEmail()}><a href="javascript:">Bestill vedlikeholdsrapport</a></div>
                </div>
                <div className="clear"></div>
            </div>
            
            <div className="right-main-content" >

            	<div className="help-area">
                	<div className="help-logo" align="right">
                    <img alt="" className="welcome-text-collapse" onClick={() => {toggleWelcomeText(!welcomeText)}}  src={welcomeText===true ? "img/arrow-right.png" : "img/arrow-down.png" } />
                  </div>
                  {welcomeText===true ? 
                  <div style={{marginTop:'-40px'}}>
                     <h1>Velkommen til Vedlikeholdsforvaltning</h1>
                      <div style={{color: 'white'}}>
                        Vedlikeholdsforvaltning AS er et selskap som tilbyr tjenester for å systematisere vedlikehold. Vi tilbyr alt fra tilstandsrapporter til komplette vedlikeholdsplaner for borettslag, sameier, aksjelag og private boliger.
                        Alle våre saksbehandlere er takstmenn sertifisert av Norsk takst som har lang erfaring og bred kompetanse på byggfag og vedlikehold.
                        <br />
                        Vedlikeholdsforvaltning tar seg av det kontinuerlige oppsynet med bygninger og eiendommer, og sørger for at vedlikehold blir fulgt opp på en forsvarlig måte. Målet er at kundene våre skal oppleve et problemfritt og forutsigbart vedlikehold som gir bedre livskvalitet og økt verdi for de som bor der. 
                        <br />
                        Vi gir deg:<br /> 
                      <div>
                        <ul>
                        <li style={{color: 'white'}}><strong>Bedre oversikt:</strong> Vi overvåker og kartlegger vedlikeholdsbehovet, og kommer med forslag til tiltak.</li>
                        <li style={{color: 'white'}}><strong>Alltid oppdatert:</strong> Myndighetenes krav til dokumentasjon på håndverkstjenester blir stadig viktigere. Vedlikeholdsforvaltning lagrer og systematiserer denne for deg i en egen mappe i vår portal som kontinuerlig oppdateres. </li>
                        <li style={{color: 'white'}}><strong>Ingen overraskelser:</strong> Med kontinuerlig oppfølging vil sjansen for uventede kostnader reduseres drastisk. </li>
                        <li style={{color: 'white'}}><strong>Egen saksbehandler:</strong> Når du inngår et kundeforhold med oss, får du en egen saksbehandler som kjenner og følger opp bygningene på eiendommen din. Har du spørsmål kan du når som helst ta kontakt med din saksbehandler via telefon eller mail. </li>
                        <li style={{color: 'white'}}><strong>Alt på ett sted:</strong> Ofte kan det være fint med alt på ett sted. Vedlikeholdsforvaltning kan hjelpe deg med det meste når det kommer til drift og vedlikehold, og vi sørger for at dere har full oversikt over status på alle bygninger. Enkelt, hva?</li>
                        </ul>
                      </div>
                     </div>
                  </div>
                   : ''}
                  
	             </div>
                <div className="divider"></div>
                
                <div className="boxes-left">
                	<div className="data-box-01">
                        <div className="data-box-top">
                          <div className="building-top" style={{float: 'left'}}>
                            <h1>Innledning/forutsetninger</h1> 
                          </div>
                          <div  className="building-top-select" style={{float: 'right'}}>
                            {buildingList ? 
                            <>
                            <select onChange={changeBuilding}>
                              <option key="0" value="0">Velg Bygning</option>
                              {buildingList.map((value, key) => (
                                <option selected={value.bygningerid===localStorage.getItem("buildingid") ? true : value.bygningerid===selectedBuilding ? true : false} onClick={changeBuilding} key={key} value={value.bygningerid}>{value.navn}</option>
                              ))}
                              
                              
                            </select>
                            </>
                            : 
                            <></> }
                          </div>
                          
                        </div>
                        <div className="data-box-content">
                            <div className="db-content-top">

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="data-box-table">
                                  <tbody>
                                  <tr>
                                    {buildingInfo ? <><td align="left"><h3>Navn</h3><h2>{buildingName}</h2></td>
                                    <td align="right"><h3>Byggeår</h3><h2>{buildingYear}</h2></td></> : ''}
                                    
                                  </tr>
                                  </tbody>
                                </table>
                            </div>
                            
                            <div className="db-content">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                   <tbody>
                                   <tr>
                                    <td align="left" width="49%">
                                        <div className="db-content-box">
                                            <h4>Beskrivelse</h4>
                                            {buildingInfo ? <div dangerouslySetInnerHTML={{__html: buildingDescription}} /> : ''}
                                            
                                        </div>
                                    </td>
                                    <td align="right"  width="20px"></td>
                                    <td align="right" valign="top"  width="49%"><img alt="" src={buildingImage} width="100%" /></td>
                                  </tr>
                                  </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <a href="#activitylist" style={{float: 'left', width: '24%'}}>
                    {pendingTask.red==='0' ? 
                      pendingTask.yellow==='0' ?

                      (<div className="data-box-02-congrat" align="center">
                          <img alt="" src="img/icons/congratulations.png" />
                          <h1>Gratulerer</h1>
                          <h2>Du er ferdig med alle viktige oppgaver</h2>
                      </div>)

                       : 
                       
                       (<div className="data-box-02-progress" align="center">
                          <img alt="" src="img/icons/pending-icon.png" />
                          <div className="pending-count">{pendingTask.yellow}</div>
                          <h2>Trenger oppfølging</h2>
                      </div>)
                      
                    : 
                    (<div className="data-box-02-pending" align="center">
                        <img alt="" src="img/icons/pending-icon.png" />
                        <div className="pending-count">{pendingTask.red}</div>
                        <h1>Oppgaver</h1>
                        <h2>Trenger oppfølging</h2>
                    </div>)
                    }
                    
                    </a>
                    <div className="data-box-04" style={{marginTop: '30px'}}>
                	<div className="data-box-top"><h1 id="activitylist">Dine oppgaver</h1></div>
                    <div className="data-box-content" style={{maxHeight: '700px', overflow: 'auto', border: '0px solid red', width: '100%'}}>
                    	<table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                          <tbody>
                          <tr align="left">
                            <th scope="col" width="10%">Prioritet</th>
                            <th scope="col" width="50%">Tiltak/Navn</th>
                            <th scope="col" width="10%">Tidsfrist</th>
                            <th scope="col" width="10%">Type</th>
                            
                            <th scope="col" width="5%">&nbsp;</th>
                          </tr>
                          <tr>
                            <td colSpan="6">&nbsp;</td>
                          </tr>
                          {taskList ? 

                            <>

                            {taskList.map((val, key) => (
                              <><tr key={key} className="db-table-tr">
                                <td>
                                {val.status==='1' ? <div>Gjennomført</div> : val.status==='2' ? <div id={val.id+'-status-'+val.type} className="task_status yellow">Viktig</div> : <div id={val.id+'-status-'+val.type} className="task_status red">Kritisk</div>}</td>
                                <td><div  id="reportHtmlArea" dangerouslySetInnerHTML={{ __html: val.tiltak }}></div></td>
                                <td>{val.deadline}</td>
                                <td>{val.type}</td>
                                <td style={{display:'none'}}>{val.type==='Avtaler' ? '' : <a href="mailto:post@vedlikeholdsforvaltning.no"><img alt=""  src="img/help.png" /></a> }</td>
                                <td align="center">{val.type==='HMS' ? <input type="checkbox" value={val.id} onClick={() => { openHMSModal(val.id) }} /> : val.type==='Avtaler' ? <input type="checkbox" value={val.id} onChange={() => {openAvtalerModal(val.id)}} /> : <input type="checkbox" value={val.id} onChange={() => {openVedlikeModal(val.id)}} /> }</td>
                              </tr>
                              <tr>
                                <td colSpan="6"><hr /></td>
                              </tr></>
                            ))} 
                            </> 
                          : ''}
                          
                          </tbody>
                          </table>  
                      </div>
                    </div>
                
                
                </div>
                
                
                <div className="boxes-right">
                	<div className="data-box-03">
                        <div className="data-box-top"><h1>Styret</h1></div>
                        <div className="data-box-content">
                            <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                              <tbody>
                              <tr align="left">
                                <th scope="col">Name</th>
                                <th scope="col">Role</th>
                                <th scope="col">Contact</th>
                                <th scope="col">&nbsp;</th>
                              </tr>
                              <tr>
                                <td colSpan="4">&nbsp;</td>
                              </tr>
                              {contactList ? contactList.map((value, key) => (
                                
                                <><tr key={key} className="db-table-tr">
                                  <td>{value.navn}</td>
                                  <td>{value.funksjon}</td>
                                  <td >{value.epost}</td>
                                  <td align="center"><a href={'mailto: '+value.epost}><img alt=""  src="img/mail.png" /></a></td>
                                </tr>
                                <tr>
                                  <td colSpan="4"><hr /></td>
                                </tr></>
                              )) : ''}
                              </tbody>
                            </table>
    
                          </div>
                          <div align="right" style={{padding:''}}>
                                <div className="email-btn"><a onClick={() => sendEmailToAll()} >Send e-post til alle</a></div>
                            </div>
                        </div>
                    
                    <div className="data-box-03" style={{marginTop: '30px'}}>
                        <div className="data-box-top"><h1>Aktiviteter</h1></div>
                        <div className="data-box-content">
                            {activityList ? activityList.map((value, key) => (
                              <div key={key} className="activity-bar">
                                <table width="95%" border="0" cellSpacing="0" cellPadding="0" align="center">
                                  <tbody>
                                  <tr>
                                    <td width="36"><img alt=""  src="img/plumber.png" width="36" /></td>
                                    <td width="20">&nbsp;</td>
                                    <td><h4>{value.status_date}</h4> <h3>{value.navn}</h3></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            )) : ''}
                            
                            <div className="activity-bar-bottom" >
                                <table width="95%" border="0" cellSpacing="0" cellPadding="0" align="center">
                                  <tbody>
                                  <tr>
                                    <td align="left"><a style={{display:'none'}} href="#">Se årshjul</a></td>
                                    <td align="right"><NavLink  to="/aktiviteter" title="Aktiviteter">Se alle</NavLink></td>
                                  </tr>
                                  </tbody>
                                </table>
                            </div>
                      </div>
                    </div>
                
                	 <div className="data-box-03" style={{ backgroundImage: 'url(img/helpBox.png)', marginTop: '30px', backgroundColor: '#293947', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', height: '392'}}>
                     
                    </div>
                    
                </div>
                
                
                <div className="clear"></div>
            </div>
        </div>
        
        <div className="clear"></div>
    </div>
    
    
</div>
</div>
    )
}
export default HomePage;
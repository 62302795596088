import Sidebar from './Sidebar';
import React,{useEffect, useState} from "react";
import { useNavigate, NavLink, Link  } from "react-router-dom";
import UserService from '../services/UserService.js';
import PropertyService from '../services/PropertyService.js';
//import ReactDOM from 'react-dom';
const BACKOFFICE_URL = "https://backoffice.vedl.no/";


function Hms() {
	const navigate = useNavigate();
  const [propertyList, setPropertyList] = useState([]);
  const username = localStorage.getItem('username');
      const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');

  const [propertyInfo, setPropertyInfo] = useState({
      adresse: "",
      postnr: "",
      poststed: ""
    });
    //const [folderList, setFolderList] = useState([]);
    const [HMSDocumentList,setHMSDocumentList] = useState([]);
    const [HMSActionList,setHMSActionList] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const closeMobileMenu = () => {
      document.getElementById('left-menu').style.display = 'none';
      setShowMenu(false);
    }
  const openMobileMenu = () => {
      console.log(showMenu);
      {showMenu==false ? document.getElementById('left-menu').style.display = 'block' : document.getElementById('left-menu').style.display = 'none'}
      //document.getElementById('left-menu').style.display = {showMenu=='true' ? 'block' : 'none'};
       setShowMenu(!showMenu);
    }
    

    useEffect(() => {
    	const loggedin = localStorage.getItem("loggedin");
        if(loggedin!='true'){
          navigate("/login");
        }
      if(localStorage.getItem("buildingid")==="null"){
          return;
        }
        if(localStorage.getItem("propertyid")==="null"){
          return;
        }
       PropertyService.propertyInfo(localStorage.getItem("propertyid")).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching users properties
      PropertyService.userPropertyList(username).then(
        (propertylist) => {
          setPropertyList(propertylist);
        },
        (error) => {
          console.log(error);
        }
      );
   
     //fetching HMS Docs
      PropertyService.hmsDocumentList(localStorage.getItem("propertyid")).then(
        (hmsdoclist) => {
          setHMSDocumentList(hmsdoclist);
        },
        (error) => {
          console.log(error);
        }
      );

      
      //fetching HMS Actions
      PropertyService.hmsActionList(localStorage.getItem("propertyid")).then(
        (hmsactionlist) => {
          setHMSActionList(hmsactionlist);
        },
        (error) => {
          console.log(error);
        }
      );


    }, [username])

    const sendMaintenanceReportEmail = () => {
      UserService.sendMaintenanceReportEmail(propertyInfo, name, email);
     }

    const sendServiceEmail = () => {
      UserService.sendServiceEmail(propertyInfo, name, email);
    }
    
    const changeProperty = (event) => {
      if(event.target.value==="0"){
        return;
      } else {
        localStorage.setItem("propertyid", "")
        localStorage.setItem("propertyid", event.target.value)
      }
      
      PropertyService.propertyInfo(event.target.value).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

     //fetching HMS Docs
      PropertyService.hmsDocumentList(event.target.value).then(
        (hmsdoclist) => {
          setHMSDocumentList(hmsdoclist);
        },
        (error) => {
          console.log(error);
        }
      );

      
      //fetching HMS Actions
      PropertyService.hmsActionList(event.target.value).then(
        (hmsactionlist) => {
          setHMSActionList(hmsactionlist);
        },
        (error) => {
          console.log(error);
        }
      );

      

    }


  return (
    <div>
    <div className="main_container">
    <div className="top-header">
      <div className="header-center">
            <div className="mobile-ham-menu"> 
           <img src="img/ham-menu-icon.png" onClick={openMobileMenu} />
           </div>
          <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="top-search">
              

              <select onChange={changeProperty} defaultValue={localStorage.getItem("propertyid")}>
                  <option key="0" value="0">Velg Eiendom</option>
                {propertyList.map((value, key) => (
                  <option selected={value.companyId===localStorage.getItem("propertyid") ? true : false} onClick={changeProperty} key={key} value={value.companyId}>{value.companyName}</option>
                  ))}
                
              </select>
            </div>
            <div className="profile-area">
              <NavLink to="/profile" title="Profil"><img alt="" src="img/profile-icon.png" height="35" /></NavLink>
              <div className="profile-username">{username}</div>
              <div className="logout-link"><Link className="logout-link" to="/logout">Logout</Link></div>
              </div>
            <div className="clear"></div>
            
        </div>
    </div>
    
    <div className="body-container" id="body-container">
      
        <Sidebar />
        <div className="right-content"  onClick={closeMobileMenu}>
          <div className="content-top">
              <div className="content-top-left"><h1>{propertyInfo.adresse===""  ? "" : propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed}</h1></div>
                <div className="content-top-right">
                  <div className="top-btn"  onClick={() => sendServiceEmail()}><a href="javascript:">Bestill tjenester</a></div>
                  <div className="top-btn" onClick={() => sendMaintenanceReportEmail()}><a href="javascript:">Bestill vedlikeholdsrapport</a></div>
                </div>
                <div className="clear"></div>
            </div>
            
            <div className="right-main-content">
              
              <div className="data-box-04" >
               
                  <div className="data-box-content" style={{maxHeight: '700px', overflow: 'auto', border: '0px solid red', width: '100%'}}>
                        <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                        <tbody>
                        <tr align="left">
                          <td>
                            <div className="sample-pdfs">
                              <div className="pdf">
                                <img src="img/pdf-icon.png" />
                                <div>Internkontroll styret</div>
                                <div>
                                <a target="_blank" href="https://backoffice.vedl.no/upload/HMS/Internkontroll-styret.pdf">Nedlast</a>
                                </div>
                              </div>
                              <div className="pdf">
                                <img src="img/pdf-icon.png" />
                                <div>Avviksprotokoll</div>
                                <div><a target="_blank" href="https://backoffice.vedl.no/upload/HMS/Avviksprotokoll.pdf">Nedlast</a></div>
                              </div>
                              <div className="pdf">
                                <img src="img/pdf-icon.png" />
                                <div>Dette sier loven</div>
                                <div><a target="_blank" href="https://backoffice.vedl.no/upload/HMS/Dette-sier-loven.pdf">Nedlast</a></div>
                              </div>
                            
                            </div>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                    
                  </div>
                </div>
                <div className="divider"></div>
                <div className="clear"></div>
                <br />
                <div className="data-box-04" >
                <div className="servicehefte-box-top"><h1>HMS</h1></div>
                  <div className="data-box-content" style={{maxHeight: '700px', overflow: 'auto', border: '0px solid red', width: '100%'}}>
                    <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                        <tbody>
                        <tr align="left">
                          <th scope="col" style={{width:'15%'}}>Navn</th>
                          <th scope="col" style={{width:'15%'}}>Type</th>
                          <th scope="col" style={{width:'22%'}}>Dokumenter</th>
                        </tr>
                        <tr>
                          <td colSpan="7">&nbsp;</td>
                        </tr>
                        {HMSDocumentList ? 

                          <>

                          {HMSDocumentList.map((val) => (
                            <><tr className="db-table-tr">
                              <td>{val.navn}</td>
                              <td>{val.type}</td>
                              <td>{val.documents ? val.documents.map((dval) => (

                                <> <a title={dval.web_path.slice(20, 30)} rel="noreferrer" target="_blank" href={BACKOFFICE_URL+''+dval.web_path}><div className="hms_docs">{dval.filename}</div></a></>
                                )) : ''}</td>
                            </tr>
                            <tr>
                              <td colSpan="7"><hr /></td>
                            </tr>
                            </>
                          ))} 
                          </> 
                        : ''}
                        
                        </tbody>
                        </table>  
                    </div>
                </div>
                <div className="divider"></div>
                <div className="clear"></div>
                <br />
                <div className="servicehefte-box-top"><h1>HMS Actions</h1></div>
                  <div className="data-box-content" style={{maxHeight: '700px', overflow: 'auto', border: '0px solid red', width: '100%'}}>
                    <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                        <tbody>
                        <tr align="left">
                          <th scope="col" style={{width:'15%'}}>Avviksnummer</th>
                          <th scope="col" style={{width:'15%'}}>Dato meldt</th>
                          <th scope="col" style={{width:'15%'}}>Oppdaget av/meldet av</th>
                          <th scope="col" style={{width:'15%'}}>Beskrivelse av avik </th>
                          <th scope="col" style={{width:'15%'}}>Sakgsgang</th>
                          <th scope="col" style={{width:'15%'}}>Resultat</th>
                          <th scope="col" style={{width:'15%'}}>Sak avsluttet dato </th>
                        </tr>
                        <tr>
                          <td colSpan="7">&nbsp;</td>
                        </tr>
                        {HMSActionList ? 

                          <>

                          {HMSActionList.map((val) => (
                            <><tr className="db-table-tr">
                              <td>{val.avviksnummer}</td>
                              <td>{val.dato_meldt}</td>
                              <td>{val.medlt_av}</td>
                              <td>{val.beskrivelse}</td>
                              <td>{val.sakgsgang}</td>
                              <td>{val.resultat}</td>
                              <td>{val.dato_sak_avsluttet}</td>
                              
                            </tr>
                            <tr>
                              <td colSpan="7"><hr /></td>
                            </tr>
                            </>
                          ))} 
                          </> 
                        : ''}
                        
                        </tbody>
                        </table>  
                   
                </div></div>
                
                <div className="divider"></div>
                <div className="clear"></div>
                
                <>
                
                </>
                
            </div>
            
        </div>
        
        <div className="clear"></div>
    </div>
    
    

</div>
    )
}
export default Hms;